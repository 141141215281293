
import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import "animate.css/animate.min.css";

function Login() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const apiUrl = "https://volumetricvideoevaluation.com/api";

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
        const response = await axios.post(`${apiUrl}/login`, {
        email,
      });

      if (response.data && response.data.user_id) {
        const loginTime = new Date().toLocaleString();

        // Store data in sessionStorage
        sessionStorage.setItem("user_id", response.data.user_id);
        sessionStorage.setItem("user_name", response.data.name); // Store the user's name
        sessionStorage.setItem("loginTime", loginTime);

        setSuccess("Login successful!");
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000); // Redirect after 2 seconds
      } else {
        setError("Login failed: No user ID returned from server.");
      }
    } catch (err) {
      setError("Invalid email");
    }
  };

  return (
    <div className="container mt-5">
      <div className="video-container">
        <video autoPlay loop muted playsInline className="background-video">
          <source src="/videos/video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      {/* Overlay Content */}
      <div className="overlay-content">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="card p-4 shadow-sm transparent-card animate__animated animate__fadeInUp">
              <h2 className="text-center mb-4 heading">Login</h2>
              <form onSubmit={handleLogin}>
                <div className="mb-3">
                  <label className="mb-2">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-submit w-100 mt-4">
                  Login
                </button>
              </form>
              {error && (
                <p className="text-white mt-3 animate__animated animate__shakeX">
                  {error}
                </p>
              )}
              {success && (
                <p className="text-white mt-3 animate__animated animate__fadeIn">
                  {success}
                </p>
              )}

              {/* Sign Up Prompt */}
              <div className="text-center mt-4">
                <p className="text-light">
                  Don't have an account?{" "}
                  <Link to="/signup" className="heading fw-bold">
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
