import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../App.css";
import "animate.css/animate.min.css";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

const apiUrl = "https://volumetricvideoevaluation.com/api";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      const response = await axios.post(`${apiUrl}/contact`,
        formData
      );

      if (response.data && response.data.message) {
        setSuccess("Message submitted successfully!");
        setFormData({ name: "", email: "", message: "" });
        setTimeout(() => {
          navigate("/");
        }, 2000); // Redirect after 2 seconds
      } else {
        setError("Submission failed.");
      }
    } catch (err) {
      setError("Failed to submit the form. Please try again.");
    }
  };

  return (
    <div className="container mt-5">
      <div className="video-container">
        <video autoPlay loop muted playsInline className="background-video">
          <source src="/videos/video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      {/* Overlay Content */}
      <div className="overlay-content">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card p-4 shadow-sm transparent-card animate__animated animate__fadeInUp">
              <h2 className="text-center mb-4 heading">Contact Us</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="mb-2">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">Message</label>
                  <textarea
                    className="form-control"
                    placeholder="Enter your message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="3"
                    required
                  />
                </div>
                <button type="submit" className="btn btn-submit w-100 mt-4">
                  Submit
                </button>
              </form>
              {error && (
                <p className="text-danger mt-3 animate__animated animate__shakeX">
                  {error}
                </p>
              )}
              {success && (
                <p className="text-success mt-3 animate__animated animate__fadeIn">
                  {success}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
