import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import "animate.css/animate.min.css";

function Signup() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  
  const apiUrl = "https://volumetricvideoevaluation.com/api";

  const handleSignup = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!/^[a-zA-Z\s]+$/.test(name)) {
      setError("Please enter a valid name");
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/signup`, {
        name,
        email,
      });

      if (response.data.message) {
        setSuccess(response.data.message); // Set the success message
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    } catch (err) {
      if (err.response && err.response.data.error) {
        setError(err.response.data.error); // Show specific error message
      } else {
        setError("Signup failed. Please try again.");
      }
    }
  };

  return (
    <div className="container mt-5">
      <div className="video-container">
        <video autoPlay loop muted playsInline className="background-video">
          <source src="/videos/video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="overlay-content">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="card p-4 shadow-sm transparent-card animate__animated animate__fadeInUp">
              <h2 className="text-center mb-4 heading">Signup</h2>
              <form onSubmit={handleSignup}>
                <div className="mb-3">
                  <label className="mb-2">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-submit w-100 mt-4">
                  Signup
                </button>
              </form>
              {error && (
                <p className="text-white mt-3 animate__animated animate__shakeX">
                  {error}
                </p>
              )}
              {success && (
                <p className="text-white mt-3 animate__animated animate__fadeIn">
                  {success}
                </p>
              )}
              <div className="text-center mt-4">
                <p className="text-light">
                  Already have an account?{" "}
                  <Link to="/login" className="heading fw-bold">
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
