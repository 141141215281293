import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../App.css";
function Questionnaire() {
  const [formData, setFormData] = useState({
    age: "",
    gender: "",
    education: "",
    field_of_study: "", // Updated name
    familiarity: "",
    experience: "",
    understanding: "",
    technical_knowledge: "", // Updated name
    quality_metrics: "", // Corrected based on backend
    previous_participation: "", // Corrected
    quiz1: "",
    quiz2: "",
    quiz3: "",
    consent: false,
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const userId = sessionStorage.getItem("user_id");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formData.age) formErrors.age = "Age is required";
    if (!formData.gender) formErrors.gender = "Gender is required";
    if (!formData.education) formErrors.education = "Education is required";
    if (!formData.field_of_study)
      formErrors.field_of_study = "Field of Study is required";
    if (!formData.familiarity)
      formErrors.familiarity = "Familiarity is required";
    if (!formData.understanding)
      formErrors.understanding = "Understanding is required";
    if (!formData.technical_knowledge)
      formErrors.technical_knowledge = "Technical Knowledge is required";
    if (!formData.quality_metrics)
      formErrors.quality_metrics = "Quality Metrics familiarity is required";
    if (!formData.previous_participation)
      formErrors.previous_participation = "Previous Participation is required";
    if (!formData.quiz1) formErrors.quiz1 = "Quiz 1 is required";
    if (!formData.quiz2) formErrors.quiz2 = "Quiz 2 is required";
    if (!formData.quiz3) formErrors.quiz3 = "Quiz 3 is required";
    if (!formData.consent)
      formErrors.consent = "You must agree to participate in this study";

    return formErrors;
  };

const apiUrl = "https://volumetricvideoevaluation.com/api";

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      await axios.post(`${apiUrl}/questionnaire`, {
        ...formData,
        user_id: userId, // Make sure this is not null
      });
      setSuccessMessage("Questionnaire submitted successfully!");

      window.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } catch (error) {
      console.error("Error submitting questionnaire:", error);
    }
  };

  return (
    <div className="dashboard-background">
      <div className="container mt-5 mb-5">
        <h2 className="text-center heading mb-4 animate__animated animate__fadeInDown">
          Research Study Questionnaire
        </h2>

        {successMessage && (
          <div className="alert alert-success animate__animated animate__fadeIn">
            {successMessage}
          </div>
        )}

        <form
          onSubmit={handleSubmit}
          className="p-4  shadow-sm transparent-card animate__animated animate__zoomIn">
          {/* Age */}
          <div className="form-group">
            <label className="mb-2">Age:</label>
            <input
              type="number"
              name="age"
              className="form-control"
              value={formData.age}
              onChange={handleChange}
            />
            {errors.age && <div className="text-danger">{errors.age}</div>}
          </div>

          {/* Gender */}
          <div className="form-group mt-3">
            <label className=" mb-2">Gender:</label>
            <select
              name="gender"
              className="form-control"
              value={formData.gender}
              onChange={handleChange}>
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Non-binary">Non-binary</option>
              <option value="Prefer not to say">Prefer not to say</option>
              <option value="Other">Other</option>
            </select>
            {errors.gender && (
              <div className="text-danger">{errors.gender}</div>
            )}
          </div>

          {/* Education */}
          <div className="form-group mt-3">
            <label className=" mb-2">Educational Background:</label>
            <select
              name="education"
              className="form-control"
              value={formData.education}
              onChange={handleChange}>
              <option value="">Select Education</option>
              <option value="High School">High School</option>
              <option value="Bachelor's Degree">Bachelor's Degree</option>
              <option value="Master's Degree">Master's Degree</option>
              <option value="Ph.D.">Ph.D.</option>
              <option value="Other">Other</option>
            </select>
            {errors.education && (
              <div className="text-danger">{errors.education}</div>
            )}
          </div>

          {/* Field of Study */}
          <div className="form-group mt-3 ">
            <label className=" mb-2">Field of Study/Occupation:</label>
            <select
              name="field_of_study" // Updated to match backend
              className="form-control"
              value={formData.field_of_study}
              onChange={handleChange}>
              <option value="">Select Field of Study</option>
              <option value="Engineering">Engineering</option>
              <option value="Arts and Creative Industries">
                Arts and Creative Industries
              </option>
              <option value="Film">Film</option>
              <option value="Other">Other</option>
            </select>
            {errors.field_of_study && (
              <div className="text-danger">{errors.field_of_study}</div>
            )}
          </div>

          {/* Familiarity */}
          <div className="form-group mt-3">
            <label className=" mb-2">
              How familiar are you with volumetric video content?
            </label>
            <select
              name="familiarity"
              className="form-control"
              value={formData.familiarity}
              onChange={handleChange}>
              <option value="">Select Familiarity</option>
              <option value="Very Familiar">Very Familiar</option>
              <option value="Somewhat Familiar">Somewhat Familiar</option>
              <option value="Not Familiar">Not Familiar</option>
            </select>
            {errors.familiarity && (
              <div className="text-danger">{errors.familiarity}</div>
            )}
          </div>

          {/* Experience */}
          <div className="form-group mt-3">
            <label className=" mb-2">Experience with Volumetric Content:</label>
            <textarea
              name="experience"
              className="form-control"
              value={formData.experience}
              onChange={handleChange}></textarea>
            {errors.experience && (
              <div className="text-danger">{errors.experience}</div>
            )}
          </div>

          {/* Understanding */}
          <div className="form-group mt-3">
            <label className="mb-2">
              Rate your understanding of volumetric video (1-5):
            </label>
            <input
              type="number"
              min="1"
              max="5"
              name="understanding"
              className="form-control"
              value={formData.understanding}
              onChange={handleChange}
            />
            {errors.understanding && (
              <div className="text-danger">{errors.understanding}</div>
            )}
          </div>

          {/* Technical Knowledge */}
          <div className="form-group mt-3">
            <label className=" mb-2">
              Technical Knowledge of Volumetric Video:
            </label>
            <select
              name="technical_knowledge" // Updated to match backend
              className="form-control"
              value={formData.technical_knowledge}
              onChange={handleChange}>
              <option value="">Select Knowledge Level</option>
              <option value="Very Knowledgeable">Very Knowledgeable</option>
              <option value="Somewhat Knowledgeable">
                Somewhat Knowledgeable
              </option>
              <option value="Not Knowledgeable">Not Knowledgeable</option>
            </select>
            {errors.technical_knowledge && (
              <div className="text-danger">{errors.technical_knowledge}</div>
            )}
          </div>

          {/* Quality Metrics */}
          <div className="form-group mt-3">
            <label className="mb-2">
              Familiarity with Video Quality Metrics:
            </label>
            <select
              name="quality_metrics" // Corrected field name
              className="form-control"
              value={formData.quality_metrics}
              onChange={handleChange}>
              <option value="">Select Familiarity</option>
              <option value="Very Familiar">Very Familiar</option>
              <option value="Somewhat Familiar">Somewhat Familiar</option>
              <option value="Not Familiar">Not Familiar</option>
            </select>
            {errors.quality_metrics && (
              <div className="text-danger">{errors.quality_metrics}</div>
            )}
          </div>

          {/* Previous Participation */}
          <div className="form-group mt-3">
            <label className=" mb-2">Previous Research Participation:</label>
            <select
              name="previous_participation" // Corrected field name
              className="form-control"
              value={formData.previous_participation}
              onChange={handleChange}>
              <option value="">Select Participation</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            {errors.previous_participation && (
              <div className="text-danger">{errors.previous_participation}</div>
            )}
          </div>

          {/* Quiz Questions */}
          <div className="form-group mt-3">
            <label className=" mb-2">
              What is a key characteristic of volumetric video?
            </label>
            <select
              name="quiz1"
              className="form-control"
              value={formData.quiz1}
              onChange={handleChange}>
              <option value="">Select Answer</option>
              <option value="Captures 3D space">Captures 3D space</option>
              <option value="Captures 2D space">Captures 2D space</option>
              <option value="Only sound">Only sound</option>
              <option value="None of the above">None of the above</option>
            </select>
            {errors.quiz1 && <div className="text-danger">{errors.quiz1}</div>}
          </div>

          <div className="form-group mt-3">
            <label className=" mb-2">
              How is volumetric video typically rendered?
            </label>
            <select
              name="quiz2"
              className="form-control"
              value={formData.quiz2}
              onChange={handleChange}>
              <option value="">Select Answer</option>
              <option value="With a standard video camera">
                With a standard video camera
              </option>
              <option value="Using multiple cameras and depth sensors">
                Using multiple cameras and depth sensors
              </option>
              <option value="Via 2D animation software">
                Via 2D animation software
              </option>
              <option value="None of the above">None of the above</option>
            </select>
            {errors.quiz2 && <div className="text-danger">{errors.quiz2}</div>}
          </div>

          <div className="form-group mt-3">
            <label className=" mb-2">
              Which of the following is a common use case for volumetric video?
            </label>
            <select
              name="quiz3"
              className="form-control"
              value={formData.quiz3}
              onChange={handleChange}>
              <option value="">Select Answer</option>
              <option value="Text Messaging">Text Messaging</option>
              <option value="Virtual Reality Experiences">
                Virtual Reality Experiences
              </option>
              <option value="Music Production">Music Production</option>
              <option value="None of the above">None of the above</option>
            </select>
            {errors.quiz3 && <div className="text-danger">{errors.quiz3}</div>}
          </div>

          {/* Consent */}
          <div className="form-group form-check mt-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="consent"
              name="consent"
              checked={formData.consent}
              onChange={handleChange}
            />
            <label className="form-check-label mb-2" htmlFor="consent">
              I agree to participate in this study and understand that my data
              will be used for research purposes.
            </label>
            {errors.consent && (
              <div className="text-danger">{errors.consent}</div>
            )}
          </div>

          <button
            type="submit"
            className="btn btn-submit mt-4 mb-5 w-100 custom-button animate__animated animate__pulse">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Questionnaire;
