import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import "../App.css";
import "../admin_components/Admin.css";

const VideoFeedbackList = () => {
  const [feedbackList, setFeedbackList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

const apiUrl = "https://volumetricvideoevaluation.com/api";

  useEffect(() => {
    const fetchFeedbackList = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/admin/view-all-video-feedback`
        );
        console.log("Fetched feedback list:", response.data);

        // Ensure feedbackList is always an array
        const feedbackData = Array.isArray(response.data)
          ? response.data
          : [response.data];
        setFeedbackList(feedbackData); // Set feedback data as an array
        setLoading(false);
      } catch (error) {
        console.error("Error fetching feedback list:", error);
        setError("Failed to load feedback. Please try again later.");
        setLoading(false);
      }
    };

    fetchFeedbackList();
  }, []);

  if (loading) {
    return <p className="text-white text-center">Loading feedback list...</p>;
  }

  if (error) {
    return <p className="text-white text-center">{error}</p>;
  }

  return (
    <div className="admin-background">
      {/* Background spots */}
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>

      <div className="container mt-5">
        <h3 className="text-white text-center">Video Feedback List</h3>
        {feedbackList.length > 0 ? (
          <Table bordered hover responsive className="mt-3 transparent-table">
            <thead>
              <tr>
                <th className="p-3">Video Name</th>
                <th className="p-3">User ID</th>
                <th className="p-3">Visual Clarity</th>
                <th className="p-3">Depth Perception</th>
                <th className="p-3">Ease of Interaction</th>
                <th className="p-3">Overall Experience</th>
                <th className="p-3">Comments</th>
                <th className="p-3">Submitted Date</th>
              </tr>
            </thead>
            <tbody>
              {feedbackList.map((feedback, index) => (
                <tr key={index}>
                  <td>{feedback.video_name}</td>
                  <td>{feedback.user_id}</td>
                  <td>{feedback.visual_clarity}</td>
                  <td>{feedback.depth_perception}</td>
                  <td>{feedback.ease_of_interaction}</td>
                  <td>{feedback.overall_experience}</td>
                  <td>{feedback.comments || "No comments"}</td>
                  <td>{new Date(feedback.submitted_at).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p className=" mt-5 text-white text-center">
            No feedbacks submitted yet.
          </p>
        )}
      </div>
    </div>
  );
};

export default VideoFeedbackList;
