import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../App.css";
const Navbar = () => {
  const navigate = useNavigate();

  // Check if session exists and retrieve username from sessionStorage
  const isLoggedIn = sessionStorage.getItem("user_id"); // Check if user_id is set
  const username = sessionStorage.getItem("user_name"); // Retrieve the username
  const userId = sessionStorage.getItem("user_id");

  const handleLogout = async () => {
    if (userId) {
      try {
        // Log the logout event via API with the correct '/api' prefix
        await axios.post(
          `http://localhost:5000/api/log-logout-event/${userId}`
        );
      } catch (error) {
        console.error("Error logging logout event:", error);
      }
    }

    // Clear sessionStorage and redirect
    sessionStorage.clear();
    navigate("/login");
  };
  useEffect(() => {
    // Function to handle closing the navbar when clicking outside
    const handleOutsideClick = (event) => {
      const navbarToggler = document.querySelector(".navbar-toggler");
      const navbarCollapse = document.querySelector("#navbarNav");

      if (
        navbarCollapse.classList.contains("show") && // Check if the navbar is open
        !navbarCollapse.contains(event.target) && // Check if click is outside the navbar
        !navbarToggler.contains(event.target) // Check if click is not on the toggle button
      ) {
        navbarToggler.click(); // Trigger the navbar toggle to close it
      }
    };

    // Add event listener when component mounts
    document.addEventListener("click", handleOutsideClick);

    // Cleanup event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  return (
    <nav
      className="navbar navbar-expand-lg shadow-sm"
      style={{
        color: "#161d6f",
        backgroundcolor: "rgb(42 42 42 / 38%)",
        borderBottom: "1px solid white",
      }}>
      <div className="container">
        {/* Logo */}
        <Link
          className="navbar-brand fw-bold"
          to="/"
          style={{
            fontSize: "2.0rem", // Larger font size for Playfair
            letterSpacing: "1px", // Adjust letter spacing
            fontFamily: "'Playfair Display', serif", // Use Playfair Display font
            background: "linear-gradient(45deg, #ffff, #ffff)", // Gradient effect
            WebkitBackgroundClip: "text", // Clip the background to text
            color: "transparent",
            fontWeight: "bold", // Transparent text to show gradient
          }}>
          VidiQuest
        </Link>

        {/* Toggle button for mobile view */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar Links */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {isLoggedIn ? (
              <>
                {/* Show Username and Logout Button when logged in */}
                <li className="nav-item">
                  <span className="nav-link fw-bold " style={{ color: "#fff" }}>
                    {" "}
                    Welcome, {username.toUpperCase()}
                  </span>
                </li>
                <li className="nav-item">
                  <Link
                    to="/dashboard"
                    className="nav-link fw-bold "
                    style={{ color: "#fff" }}>
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <button
                    className="btn btn-submit-danger ms-3"
                    onClick={handleLogout}
                    style={{ color: "#fff" }}>
                    {" "}
                    Logout
                  </button>
                </li>
              </>
            ) : (
              <>
                {/* Show Login and Register buttons when logged out */}
                <li className="nav-item">
                  <Link
                    to="/login"
                    className="nav-link nav-btn-submit fw-bold"
                    style={{ color: "#fff" }}>
                    Login
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/contact"
                    className="nav-link nav-btn-submit fw-bold"
                    style={{ color: "#fff" }}>
                    Contact
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
