import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "../App.css";

// Correctly using the apiUrl variable
const apiUrl = "https://volumetricvideoevaluation.com/api";

function DistortionExamples() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log("DistortionExamples component has mounted."); // Debug: Confirm component is rendered

  const compressionDetails = {
    texture_compression: {
      title: "Texture Compression (Geometry Constant)",
      intro:
        "In this section, we explore how texture compression affects the model's surface while maintaining the original geometry. Higher levels of texture compression result in blurriness, reduced texture detail, and potential color banding.",
      example:
        "Example: Compare an R1 to R5 compression level to show how fine details, like clothing texture, blur or lose color fidelity with higher compression.",
      details: [
        "R1: QP = 25 (High Quality, Low Compression)",
        "R2: QP = 30",
        "R3: QP = 35",
        "R4: QP = 40",
        "R5: QP = 55 (Low Quality, High Compression)",
        "Geometry QP is kept constant, while Texture QP is varied.",
      ],
    },
    geometry_compression: {
      title: "Geometry Compression (Texture Constant)",
      intro:
        "These examples show how geometry compression impacts the model’s shape and structure. Higher compression levels can lead to distorted edges, altered proportions, and reduced geometric accuracy, while textures remain unaffected.",
      example:
        "Example: Compare an R1 and R5 compressed video sequence to illustrate how increasing compression leads to more geometric inaccuracies, such as warped shapes or 'blockiness.'",
      details: [
        "R1: Geometry QP = 50 (High Quality, Low Compression)",
        "R2: Geometry QP = 52",
        "R3: Geometry QP = 53",
        "R4: Geometry QP = 54",
        "R5: Geometry QP = 55 (Low Quality, High Compression)",
        "Texture QP is kept constant at 22, while Geometry QP is varied.",
      ],
    },
    geometry_and_texture_compression: {
      title: "Geometry & Texture Compression",
      intro:
        "In this section, we look at how combined compression affects both geometry and texture. As compression levels increase, both shape and surface details show distortions, resulting in a more pronounced overall degradation.",
      example:
        "Example: Show an R1 versus R5 comparison to highlight combined impacts, where both edges and surface details appear degraded.",
      details: [
        "R1: Geometry QP = 57, Texture QP = 15 (High Quality, Low Compression)",
        "R2: Geometry QP = 59, Texture QP = 20",
        "R3: Geometry QP = 60, Texture QP = 25",
        "R4: Geometry QP = 61, Texture QP = 30",
        "R5: Geometry QP = 62, Texture QP = 35 (Low Quality, High Compression)",
        "Both Geometry and Texture QP values are varied.",
      ],
    },
    dynamic_video: {
      title: "Dynamic Background",
      intro:
        "This section showcases videos with dynamic backgrounds, providing a more varied and visually engaging context for the models. This can be used to demonstrate how the models interact with different moving backgrounds.",
      example:
        "Example: Observe how the model and the dynamic background interact and maintain quality under different compression levels.",
      details: [
        "Dynamic backgrounds are designed to add visual complexity and enhance viewer engagement.",
      ],
    },
  };

  useEffect(() => {
    const fetchVideos = async () => {
      console.log("API call initiated to fetch videos..."); // Debug: API call started
      try {
        // Correct use of template literals
        const response = await axios.get(`${apiUrl}/videos/distortion-examples`);
        console.log("API response received:", response.data); // Debug: API response received
        setVideos(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching videos:", err.message); // Debug: Log error message
        setError("Failed to load videos. Please try again later.");
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  if (loading) {
    console.log("Loading state: true"); // Debug: Confirm loading state
    return <p className="text-center text-white">Loading videos...</p>;
  }

  if (error) {
    console.log("Error state:", error); // Debug: Log error state
    return <p className="text-center text-danger mt-5">{error}</p>;
  }

  const getCompressionLevel = (videoLocation) => {
    const match = videoLocation.match(/\/(R\d)\//);
    return match ? match[1] : "Unknown";
  };

  const sortVideosByCompressionLevel = (videosArray) => {
    console.log("Sorting videos by compression level..."); // Debug: Sorting function called
    return videosArray.sort((a, b) => {
      const levelA = parseInt(getCompressionLevel(a.video_location).substring(1));
      const levelB = parseInt(getCompressionLevel(b.video_location).substring(1));
      return levelA - levelB;
    });
  };

  const videosByType = {
    texture_compression: [],
    geometry_compression: [],
    geometry_and_texture_compression: [],
    dynamic_video: [],
  };

  videos.forEach((video) => {
    console.log("Processing video:", video); // Debug: Log each video being processed
    if (video.background_type === "dynamic_video") {
      videosByType["dynamic_video"].push(video);
    } else if (video.video_location.includes("geometry_and_texture_compression")) {
      videosByType["geometry_and_texture_compression"].push(video);
    } else if (video.video_location.includes("texture_compression")) {
      videosByType["texture_compression"].push(video);
    } else if (video.video_location.includes("geometry_compression")) {
      videosByType["geometry_compression"].push(video);
    }
  });

  Object.keys(videosByType).forEach((type) => {
    if (type !== "dynamic_video") {
      console.log(`Sorting videos of type: ${type}`); // Debug: Sorting by type
      videosByType[type] = sortVideosByCompressionLevel(videosByType[type]);
    }
  });

  return (
    <div className="dashboard-background">
      <Container className="mt-5 mb-5">
        <h2 className="text-center mb-4 text-white">
          Examples of Distortion Levels
        </h2>
        {Object.keys(videosByType).map((type) => (
          <div key={type} className="mb-5">
            {videosByType[type].length > 0 && (
              <>
                <div className="transparent-card p-4 mb-4 mt-2">
                  <h4 className="text-white mb-3">
                    {compressionDetails[type].title}
                  </h4>
                  <p className="text-white">{compressionDetails[type].intro}</p>
                  <p className="text-white font-italic">
                    {compressionDetails[type].example}
                  </p>
                  <ul className="text-white">
                    {compressionDetails[type].details.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
                <Row className="g-4 justify-content-center">
                  {videosByType[type].map((video) => (
                    <Col
                      md={5}
                      lg={4}
                      key={video.video_id}
                      className="d-flex justify-content-center">
                      <Card
                        className="transparent-card shadow-sm"
                        style={{ borderRadius: "10px" }}>
                        <Card.Body>
                          {type !== "dynamic_video" ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  Compression Level:{" "}
                                  {getCompressionLevel(video.video_location)}
                                </Tooltip>
                              }>
                              <video
                                className="w-100 mb-3"
                                style={{ borderRadius: "10px" }}
                                controls
                                src={video.video_url}
                              />
                            </OverlayTrigger>
                          ) : (
                            <video
                              className="w-100 mb-3"
                              style={{ borderRadius: "10px" }}
                              controls
                              src={video.video_url}
                            />
                          )}
                          {type !== "dynamic_video" && (
                            <p className="text-center text-white">
                              <strong>Compression Level:</strong>{" "}
                              {getCompressionLevel(video.video_location)}
                            </p>
                          )}
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </div>
        ))}
      </Container>
    </div>
  );
}

export default DistortionExamples;
