import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure you're importing Bootstrap
import * as XLSX from "xlsx"; // Import the xlsx library

const AdminVideos = () => {
  const [videos, setVideos] = useState([]); // List of videos
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate(); // For navigation

const apiUrl = "https://volumetricvideoevaluation.com/api";

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/admin/videos`
        );
        // console.log("Videos fetched:", response.data);

        // Ensure response.data is an array of videos
        if (Array.isArray(response.data)) {
          setVideos(response.data); // Set the entire array of videos
        } else {
          setError("Unexpected data format");
        }
      } catch (error) {
        console.error("Error fetching videos:", error);
        if (error.response && error.response.status === 404) {
          setError("No videos found.");
        } else {
          setError("Failed to load videos. Please try again later.");
        }
      } finally {
        setLoading(false); // End loading regardless of success/failure
      }
    };

    fetchVideos();
  }, []);

  // Handle feedback deletion
  const handleDeleteFeedback = async (video_id) => {
    if (window.confirm("Are you sure you want to delete this feedback?")) {
      try {
        await axios.delete(
          `${apiUrl}/admin/delete-video-feedback/${video_id}`
        );
        alert("Feedback deleted successfully!");

        // Update the videos state after deletion
        setVideos(
          videos.map((video) =>
            video.video_id === video_id
              ? { ...video, feedback_status: "No Feedback" }
              : video
          )
        );
      } catch (error) {
        console.error("Error deleting feedback:", error);
        alert("Failed to delete feedback. Please try again.");
      }
    }
  };
  // Function to handle export to Excel (fetch feedback data when clicked)
  const handleExportToExcel = async () => {
    try {
      // Fetch video feedback data on button click
      const feedbackResponse = await axios.get(
        `${apiUrl}/admin/collect-feedback`
      );
      const feedback = feedbackResponse.data;

      // Merge assigned videos with feedback data
      const mergedData = videos.map((video) => {
        const videoFeedback = feedback.find(
          (f) => f.video_id === video.video_id
        );
        return {
          ...video,
          feedback: videoFeedback || {}, // Merge feedback (if exists)
        };
      });

      // Format data for export
      const formattedData = mergedData.map((video) => ({
        "Assignment ID": video.assignment_id,
        "User ID": video.user_id,
        "Video ID": video.video_id,
        "Video Name": video.video_name,
        Character: video.video_character,
        Background: video.background_type,
        Location: video.video_location,
        "Assigned At": video.assigned_at,
        "Visual Clarity": video.feedback.visual_clarity || "N/A",
        "Depth Perception": video.feedback.depth_perception || "N/A",
        "Ease of Interaction": video.feedback.ease_of_interaction || "N/A",
        "Overall Experience": video.feedback.overall_experience || "N/A",
        Comments: video.feedback.comments || "N/A",
        "Feedback Submitted At": video.feedback.submitted_at || "N/A",
      }));

      // Convert to worksheet and export as XLS file
      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Videos with Feedback");
      XLSX.writeFile(workbook, "videos_feedback_data.xlsx");
    } catch (error) {
      console.error("Error fetching video feedback:", error);
      alert("Failed to fetch feedback data. Please try again.");
    }
  };
  // Handle view feedback navigation
  const handleViewFeedback = (video_id) => {
    navigate(`/admin/view-feedback/${video_id}`);
  };

  // Render loading state
  if (loading) {
    return <p className="text-white text-center">Loading videos...</p>;
  }

  // Render error state
  if (error) {
    return <p className="text-white text-center">{error}</p>;
  }

  // Main render for videos list
  return (
    <div className="admin-background">
      <div className="container-fluid mt-5">
        <h3 className="text-white text-center">Videos</h3>
        <Button className="btn-submit" onClick={handleExportToExcel}>
          Export to Excel
        </Button>
        {videos.length === 0 ? (
          <h3 className="text-white text-center">No videos available</h3>
        ) : (
          <Table
            striped
            bordered
            hover
            responsive
            className="mt-3 mb-5 transparent-table">
            <thead className="text-center">
              <tr>
                <th>No</th>
                <th>Video ID</th>
                <th>Name</th>
                <th>Character</th>
                <th>Background</th>
                <th>Location</th>
                <th>User ID</th>
                <th>Assigned At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {videos.map((video, index) => (
                <tr key={video.video_id}>
                  <td className="text-center">{index + 1}</td>
                  <td>{video.video_id}</td>
                  <td>{video.video_name}</td>
                  <td>{video.video_character}</td>
                  <td>{video.background_type}</td>
                  <td
                    style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      maxWidth: "200px",
                    }}>
                    {video.video_location}
                  </td>

                  <td>{video.assigned_user_id || "Not Assigned"}</td>
                  <td>
                    {video.assigned_at
                      ? new Date(video.assigned_at).toLocaleString()
                      : "N/A"}
                  </td>
                  <td className="text-center">
                    {video.feedback_status === "Feedback Given" && (
                      <>
                        <Button
                          className="btn-submit mt-1 mb-1 font-small"
                          onClick={() => handleViewFeedback(video.video_id)}>
                          View
                        </Button>
                        <Button
                          className="btn-submit-danger mt-1 mb-1 font-small"
                          onClick={() => handleDeleteFeedback(video.video_id)}>
                          Delete
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default AdminVideos;
