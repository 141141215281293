import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../admin_components/Admin.css";

function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

const apiUrl = "https://volumetricvideoevaluation.com/api";

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      const response = await axios.post(
        `${apiUrl}/admin/login`,
        {
          email,
          password,
        }
      );

      if (response.data && response.data.admin_id) {
        const loginTime = new Date().toLocaleString();

        // Store data in sessionStorage
        sessionStorage.setItem("admin_id", response.data.admin_id);
        sessionStorage.setItem("admin_name", response.data.name); // Store the admin's name
        sessionStorage.setItem("admin_loginTime", loginTime);

        setSuccess("Login successful!");
        setTimeout(() => {
          navigate("/admin/dashboard");
        }, 2000); // Redirect after 2 seconds
      } else {
        setError("Login failed: No admin ID returned from server.");
      }
    } catch (err) {
      setError("Invalid email or password");
    }
  };

  return (
    <div className="admin-background">
      {/* Background spots */}
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>

      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="card p-4 shadow-sm transparent-card animate__animated animate__fadeInUp">
              <h2 className="text-center heading mb-4">Admin Login</h2>
              <form onSubmit={handleLogin}>
                <div className="mb-3">
                  <label className="mb-2">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-submit w-100 mt-4">
                  Login
                </button>
              </form>
              {error && (
                <p className="text-white mt-3 animate__animated animate__shakeX">
                  {error}
                </p>
              )}
              {success && (
                <p className="text-white mt-3 animate__animated animate__fadeIn">
                  {success}
                </p>
              )}

           </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
