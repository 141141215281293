import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./AdminNavbar.css"; // Import CSS here
import "../App.css";

const AdminNavbar = () => {
  const navigate = useNavigate();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false); // State for navbar toggle

  // Check if admin is logged in using admin_id
  const session = sessionStorage.getItem("admin_id");
  const adminName = sessionStorage.getItem("admin_name"); // Get admin's name

  const handleLogout = () => {
    sessionStorage.removeItem("admin_id");
    sessionStorage.removeItem("admin_name");
    sessionStorage.removeItem("admin_loginTime");

    navigate("/admin/login");
  };

  // Toggle navbar on button click
  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  // Close navbar when clicking outside of it (mobile only)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".navbar") && isNavbarOpen) {
        setIsNavbarOpen(false);
      }
    };

    // Only add event listener for mobile view
    if (window.innerWidth <= 991) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      if (window.innerWidth <= 991) {
        document.removeEventListener("click", handleClickOutside);
      }
    };
  }, [isNavbarOpen]);

  return (
    <nav
      className="navbar navbar-expand-lg shadow-sm"
      style={{
        color: "#161d6f",
        backgroundcolor: "rgb(42 42 42 / 38%)",
        borderBottom: "1px solid white",
      }}>
      <div className="container">
        <Link
          className="navbar-brand fw-bold"
          to="/admin"
          style={{
            fontSize: "2.0rem", // Larger font size for Playfair
            letterSpacing: "1px", // Adjust letter spacing
            fontFamily: "'Playfair Display', serif", // Use Playfair Display font
            background: "linear-gradient(45deg, #ffff, #ffff)", // Gradient effect
            WebkitBackgroundClip: "text", // Clip the background to text
            color: "transparent",
            fontWeight: "bold", // Transparent text to show gradient
          }}>
          VidiQuest
        </Link>

        <button
          className={`navbar-toggler ${isNavbarOpen ? "collapsed" : ""}`} // Change class based on state
          type="button"
          onClick={toggleNavbar} // Toggle on button click
          aria-controls="adminNavbarNav"
          aria-expanded={isNavbarOpen}
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`} // Apply show class only when isNavbarOpen is true
          id="adminNavbarNav">
          <ul className="navbar-nav ms-auto">
            {session ? (
              <>
                <li className="nav-item">
                  <span className="nav-link text-white">
                    Welcome, {adminName.toUpperCase()}
                  </span>
                </li>
                <li className="nav-item text-white">
                  <Link
                    to="/admin/dashboard"
                    className="nav-link fw-bold text-white">
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <button
                    className="btn btn-submit-danger ms-3"
                    onClick={handleLogout}>
                    Logout
                  </button>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link
                    to="/admin/login"
                    className="nav-link nav-btn-submit fw-bold">
                    Login
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    to="/admin/signup"
                    className="nav-link nav-btn-submit fw-bold">
                    Signup
                  </Link>
                </li> */}
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default AdminNavbar;
