import React, { useState, useEffect, useCallback, useRef } from "react";
import VideoFeedbackForm from "./VideoFeedbackForm";
import axios from "axios";
import { Card, Container, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import "../App.css";

  const VideoPlayer = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [feedbackStatus, setFeedbackStatus] = useState({});
  const userId = sessionStorage.getItem("user_id");

  const videoRefs = useRef([]); // Ref to hold all video elements

  const maxPlayTime = 12; // 12 seconds

  const checkSessionVideos = () => {
    const videosAssigned = sessionStorage.getItem("videosAssigned") === "true";
    if (videosAssigned) {
      const storedVideos = JSON.parse(sessionStorage.getItem("assignedVideos"));
      setVideos(storedVideos);

      const feedbackMap = {};
      storedVideos.forEach((video) => {
        feedbackMap[video.video_id] = video.feedback_submitted || false;
      });
      setFeedbackStatus(feedbackMap);
      return true;
    }
    return false;
  };

const apiUrl = "https://volumetricvideoevaluation.com/api";

  const assignVideos = useCallback(async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/videos/assign-videos`,
        { user_id: userId }
      );

      if (response.data.assigned) {
        setVideos(response.data.videos);
        sessionStorage.setItem(
          "assignedVideos",
          JSON.stringify(response.data.videos)
        );
        sessionStorage.setItem("videosAssigned", "true");
      }
    } catch (error) {
      console.error("Error assigning videos:", error);
    }
  }, [userId]);

  useEffect(() => {
    const initializeVideos = async () => {
      setLoading(true);
      const sessionVideosFound = checkSessionVideos();
      if (!sessionVideosFound) {
        await assignVideos();
      }
      setLoading(false);
    };

    initializeVideos();
  }, [assignVideos]);

  const handleFeedbackSubmit = async (videoId, feedback) => {
    try {
      const response = await axios.post(`${apiUrl}/videos/feedback`,
        {
          user_id: userId,
          video_id: videoId,
          ...feedback,
        }
      );

      if (response.status === 201) {
        setFeedbackStatus((prevState) => ({
          ...prevState,
          [videoId]: true,
        }));

        const storedVideos = JSON.parse(
          sessionStorage.getItem("assignedVideos")
        );
        const updatedVideos = storedVideos.map((video) =>
          video.video_id === videoId
            ? { ...video, feedback_submitted: true }
            : video
        );
        sessionStorage.setItem("assignedVideos", JSON.stringify(updatedVideos));
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

 // Function to extract and display only the compression type from video_location
  const getVideoDisplayName = (video) => {
    const { video_location, background_type } = video;

    if (background_type === "dynamic_video") {
      return "Dynamic Background"; // For dynamic videos, return this label
    }

    // Check if it's a compressed video
    if (background_type === "compressed_video") {
      if (video_location.includes("geometry_compression")) {
        return "Geometry Compression";
      } else if (video_location.includes("texture_compression")) {
        return "Texture Compression";
      } else if (video_location.includes("geometry_and_texture_compression")) {
        return "Geometry and Texture Compression";
      }
    }

    // Handle different background videos
    if (video_location.includes("50_videos_different_background")) {
      return "Background Change";
    }

    // Fallback to the video name if no specific compression is found
    return video.video_name;
  };

 // Function to stop the video when it reaches the maxPlayTime
  const handleTimeUpdate = (index) => {
    const video = videoRefs.current[index];
    if (video.currentTime >= maxPlayTime) {
      video.pause();
      video.currentTime = maxPlayTime; // To ensure it doesn't go beyond 12 seconds
    }
  };

// Tooltip message for 12s limit
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Standardized viewing durations ensure each video gains uniform attention, offering an equal opportunity 
      for message impact.
    </Tooltip>
  );

  if (loading)
    return <p className="text-center text-white">Loading videos...</p>;

  if (videos.length === 0) {
    return (
      <p className="mt-5 text-center text-white">
        No videos assigned to you yet.
      </p>
    );
  }

  return (
    <div className="dashboard-background">
      <Container className="mt-5 mb-5">

 {/* Explanation for Score (1-5) Scale */}
         <div className="transparent-card p-4">
          <h4 className="text-white">Rating Scale Guide:</h4>
          <p className="text-white">
            Use the following descriptions to guide your ratings:
          </p>
          <ul>
            <li>
              <strong>0 - Very Poor Quality:</strong> Severe distortions, very
              poor visual clarity.
            </li>
            <li>
              <strong>1 - Poor Quality:</strong> High distortion, recognizable
              but heavily affected.
            </li>
            <li>
              <strong>2 - Fair Quality:</strong> Moderate distortion, still
              acceptable.
            </li>
            <li>
              <strong>3 - Good Quality:</strong> Minor distortions, satisfactory
              overall.
            </li>
            <li>
              <strong>4 - Very Good Quality:</strong> Minimal distortion, close
              to original.
            </li>
            <li>
              <strong>5 - Excellent Quality:</strong> No visible distortion,
              nearly perfect.
            </li>
          </ul>

          <p className="text-warning mt-4 mb-4">
            If you have visual sensitivities, please maintain a comfortable
            viewing distance from your screen. Take breaks if needed.
          </p>
        </div>
        <h2 className="text-center mb-4 mt-4 heading">Rate Assigned Videos</h2>
        <Row className="g-4">
          {videos.map((video, index) => (
            <Col md={4} key={video.video_id}>
              <Card
                className="transparent-card shadow-sm"
                style={{ borderRadius: "10px" }}>
                <Card.Body>
	          <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}>
                    <video
                      ref={(el) => (videoRefs.current[index] = el)} // Store the video element in ref
                      className="w-100 mb-3"
                      style={{ borderRadius: "10px" }}
                      controls
                      src={video.video_url}
                      onTimeUpdate={() => handleTimeUpdate(index)} // Stop after 12 seconds
                    />
                  </OverlayTrigger>
		{/* Display the appropriate name based on video type */}
                  <p className="text-center text-white">
                    <strong>{getVideoDisplayName(video)}</strong>
                  </p>
                  {feedbackStatus[video.video_id] ? (
                    <p className="text-white text-center">Feedback Submitted</p>
                  ) : (
                    <VideoFeedbackForm
                      videoId={video.video_id}
                      onSubmitFeedback={handleFeedbackSubmit}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default VideoPlayer;
