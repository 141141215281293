import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button } from "react-bootstrap";
import "../admin_components/Admin.css";

const AdminMessages = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

const apiUrl = "https://volumetricvideoevaluation.com/api";

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/admin/unanswered-messages`
        );
        if (Array.isArray(response.data)) {
          setMessages(response.data);
        } else {
          console.error("Unexpected response format:", response.data);
          setError("Unexpected response format.");
        }
        setLoading(false);
      } catch (error) {
        setError("Failed to load messages.");
        setLoading(false);
      }
    };

    fetchMessages();
  }, []);

  // Redirect to Gmail with the user's email pre-filled
  const handleReply = (email) => {
    window.open(`mailto:${email}`, "_blank");
  };

  // Mark the message as answered
  const handleDelete = async (id) => {
    if (
      window.confirm("Are you sure you want to mark this message as answered?")
    ) {
      try {
        await axios.post(`${apiUrl}/admin/mark-as-answered`, {
          id,
        });
        // Refresh the list after deletion
        setMessages(messages.filter((message) => message.id !== id));
      } catch (error) {
        alert("Failed to mark message as answered.");
      }
    }
  };
  // Helper function to capitalize the first letter of each word in the name
  const capitalizeFirstLetter = (name) => {
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  if (loading) {
    return <p className="text-white text-center">Loading messages...</p>;
  }

  if (error) {
    return <p className="text-white text-center">{error}</p>;
  }

  return (
    <div className="admin-background">
      {/* Background spots */}
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>

      <div className="container mt-5">
        <h2 className="heading">Unanswered Messages</h2>
        {messages.length === 0 ? (
          <p className="text-white">No unanswered messages available.</p>
        ) : (
          <Table bordered hover responsive className="mt-3 transparent-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Message</th>
                <th>Submitted Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {messages.map((message) => (
                <tr key={message.id}>
                  <td>{capitalizeFirstLetter(message.name)}</td>
                  <td>{message.email}</td>
                  <td>{message.message}</td>
                  <td>{new Date(message.created_at).toLocaleString()}</td>
                  <td>
                    <Button
                      className="btn-submit"
                      onClick={() => handleReply(message.email)}>
                      Reply
                    </Button>{" "}
                    <Button
                      className="btn-submit-danger"
                      onClick={() => handleDelete(message.id)}>
                      Mark as Answered
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default AdminMessages;
