import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./components/NavBar"; // User navbar
import AdminNavBar from "./admin_components/AdminNavbar"; // Admin navbar

const AppWrapper = ({ children }) => {
  const location = useLocation(); // Get current location
  const isAdminRoute = location.pathname.startsWith("/admin"); // Check if the route is an admin route

  return (
    <div>
      {/* Conditionally render navbar based on the route */}
      {isAdminRoute ? <AdminNavBar /> : <Navbar />}
      {children}
    </div>
  );
};

export default AppWrapper;
