import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../admin_components/Admin.css";

const QuestionnaireList = () => {
  const [questionnaires, setQuestionnaires] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Capitalize the first letter of user names
  const capitalizeFirstLetter = (name) => {
    return name ? name.charAt(0).toUpperCase() + name.slice(1) : "";
  };

 const apiUrl = "https://volumetricvideoevaluation.com/api";

  // Fetch questionnaires when the component mounts
  useEffect(() => {
    const fetchQuestionnaires = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/admin/questionnaires`
        );

        // Log the response data
        console.log("API Response:", response.data);

        // Check if the response is either an array or a single object
        if (Array.isArray(response.data) && response.data.length > 0) {
          setQuestionnaires(response.data);
        } else if (response.data && typeof response.data === "object") {
          // If it's a single object, wrap it in an array
          setQuestionnaires([response.data]);
        } else {
          setError("No data found.");
        }
        setLoading(false);
      } catch (error) {
        setError("Failed to load questionnaires");
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    fetchQuestionnaires();
  }, []);

  // Navigate to questionnaire details
  const handleViewQuestionnaire = (id) => {
    navigate(`/admin/questionnaire/${id}`);
  };

  if (loading) {
    return <p className="text-white text-center">Loading questionnaires...</p>;
  }

  if (error) {
    return <p className="text-white text-center">{error}</p>;
  }

  return (
    <div className="admin-background">
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="container mt-5">
        <h2 className="text-center text-white mb-5 mt-5">
          Submitted Questionnaires
        </h2>
        <Table
          striped
          bordered
          hover
          responsive
          className="mt-3 transparent-table">
          <thead>
            <tr>
              <th>User Name</th>
              <th>User ID</th>
              <th>Submitted At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {questionnaires.length > 0 ? (
              questionnaires.map((questionnaire) => (
                <tr key={questionnaire.id}>
                  <td>{capitalizeFirstLetter(questionnaire.user_name)}</td>
                  <td>{questionnaire.user_id}</td>
                  <td>
                    {new Date(questionnaire.submitted_at).toLocaleString()}
                  </td>
                  <td>
                    <button
                      className="btn-submit font-small"
                      onClick={() => handleViewQuestionnaire(questionnaire.id)}>
                      View Questionnaire
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center text-white">
                  No questionnaires found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default QuestionnaireList;
