import React from "react";
import { Link } from "react-router-dom";
import "../App.css";
import "../admin_components/AdminLandingPage.css";

const AdminLandingPage = () => {
  return (
    <div className="admin-landing-page">
      {/* Background spots */}
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>

      {/* Overlay Content */}
      <div className="container text-center minus-margin-top">
        <h1 className="text-white">Welcome to the Admin Panel</h1>
        <p className="lead heading">
          Please login or signup to manage the application.
        </p>
        <div className="mt-4">
          <Link to="/admin/login" className="btn btn-submit mx-2">
            Admin Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminLandingPage;
