import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../App.css";

function Dashboard() {
  const user = sessionStorage.getItem("user_name");
  const userId = sessionStorage.getItem("user_id");
  const [hasSubmitted, setHasSubmitted] = useState(
    sessionStorage.getItem("hasSubmitted") === "true"
  );
  const [assignedVideos, setAssignedVideos] = useState(null);
  const [loadingVideos, setLoadingVideos] = useState(true);
  const navigate = useNavigate();

   const apiUrl = "https://volumetricvideoevaluation.com/api";

  // Move this function outside of useEffect so it's globally available in the component
  const checkAssignedVideos = async () => {
    if (userId) {
      try {
        const response = await axios.get(
          `${apiUrl}/videos/check-assigned-videos/${userId}`
        );
        console.log("Assigned videos response:", response.data); // Log response to check

        if (response.data.assigned) {
          setAssignedVideos(response.data.videos); // Set the assigned videos in state
          sessionStorage.setItem(
            "assignedVideos",
            JSON.stringify(response.data.videos)
          ); // Store videos in sessionStorage
          sessionStorage.setItem("videosAssigned", "true"); // Mark that videos are assigned
        } else {
          setAssignedVideos(null);
          sessionStorage.removeItem("assignedVideos"); // Remove if no videos
          sessionStorage.setItem("videosAssigned", "false");
        }
        setLoadingVideos(false); // Stop loading state
      } catch (error) {
        console.error("Error checking assigned videos:", error);
        setLoadingVideos(false); // Stop loading state in case of error
      }
    }
  };

  const assignVideos = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/videos/assign-videos`,
        { user_id: userId }
      );
      if (response.data.assigned) {
        setAssignedVideos(response.data.videos);
        sessionStorage.setItem(
          "assignedVideos",
          JSON.stringify(response.data.videos)
        );
        sessionStorage.setItem("videosAssigned", "true");
        navigate("/rate-videos", { state: { videos: response.data.videos } });
      }
    } catch (error) {
      console.error("Error assigning videos:", error);
    }
  };

  useEffect(() => {
    const checkQuestionnaireStatus = async () => {
      if (!hasSubmitted && userId) {
        try {
          const response = await axios.get(
            `${apiUrl}/check-questionnaire/${userId}`
          );
          const submitted = response.data.hasSubmitted;
          setHasSubmitted(submitted);
          sessionStorage.setItem("hasSubmitted", submitted);
        } catch (error) {
          console.error("Error checking questionnaire status:", error);
        }
      }
    };

    if (userId) {
      checkQuestionnaireStatus();
      checkAssignedVideos();
    } else {
      navigate("/login");
    }
  }, [userId, hasSubmitted, navigate]);

  const handleQuestionnaire = () => {
    navigate("/questionnaire");
  };

  const handleRateVideos = async () => {
    const videosAssigned = sessionStorage.getItem("videosAssigned") === "true";
    if (videosAssigned) {
      const savedVideos = JSON.parse(sessionStorage.getItem("assignedVideos"));
      console.log("Navigating with videos:", savedVideos); // Debugging
      navigate("/rate-videos", { state: { videos: savedVideos } });
    } else {
      console.log("No videos assigned, assigning new ones."); // Debugging
      await checkAssignedVideos(); // First check assigned videos before assigning new ones
      if (!assignedVideos) {
        await assignVideos(); // Assign new videos if none are assigned
      }
    }
  };

  const handleSeeSample = () => {
    navigate("/distortion-examples");
  };

  return (
    <div className="dashboard-background">
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card p-4 shadow-sm text-center transparent-card">
              <h2 className="mb-4 heading">Welcome, {user?.toUpperCase()}</h2>
              {hasSubmitted ? (
                <div>
                  <p className="heading mb-2">
                    Thank you for submitting the questionnaire!
                  </p>
                  <button
                    onClick={handleRateVideos}
                    className="btn btn-submit w-100 mt-2">
                    Rate Videos
                  </button>
		  <p className="text-warning mt-4 mb-4">
                    For distortion understanding click below!
                  </p>
                  <button
                    onClick={handleSeeSample}
                    className="btn btn-submit w-100 mt-2">
                    See Sample Videos
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleQuestionnaire}
                  className="btn btn-submit w-100 mt-2">
                  Submit Questionnaire
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-8">
            <div className="card p-4 shadow-sm text-white transparent-card">
              <h3 className="fw-bold mb-3 text-center">
                ITU-R BT.500 Guide for Video Rating
              </h3>
              <p>
                ITU-R BT.500 is an international standard for subjective video
                quality assessment. It provides guidelines on how to evaluate
                video content based on the opinions of viewers, ensuring that
                the process is standardized and reliable.
              </p>
              <ul>
                <li>Watch each video carefully without distractions.</li>
                <li>
                  Rate the video based on aspects like visual clarity, depth
                  perception, and overall enjoyment.
                </li>
                <li>
                  Use the provided rating scales to give objective feedback on
                  each quality aspect.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
