import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Table } from "react-bootstrap";

const ViewFeedback = () => {
  const { video_id } = useParams(); // Get video_id from the route parameters
  const [feedback, setFeedback] = useState(null); // State to hold feedback data
  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors

const apiUrl = "https://volumetricvideoevaluation.com/api";

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        console.log("Fetching feedback for video_id:", video_id); // Log video_id
        const response = await axios.get(
          `${apiUrl}/admin/view-video-feedback/${video_id}`
        );

        console.log("Fetched Feedback:", response.data);
        setFeedback(response.data[0]); // Access the first element in the array
        setLoading(false); // Stop the loading state
      } catch (error) {
        console.error("Error fetching feedback data:", error);
        setError("Failed to load feedback details");
        setLoading(false);
      }
    };

    fetchFeedback(); // Call the function to fetch feedback on mount
  }, [video_id]);

  // Render the component
  if (loading) {
    return <p>Loading feedback...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container mt-5">
      <h2 className="heading mb-5">Feedback for Video Id : {video_id}</h2>
      {feedback ? (
        <Table bordered hover className="mt-3 transparent-table">
          <thead>
            <tr>
              <th>Feedback Category</th>
              <th>Feedback Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Visual Clarity</td>
              <td>{feedback.visual_clarity}</td>
            </tr>
            <tr>
              <td>Depth Perception</td>
              <td>{feedback.depth_perception}</td>
            </tr>
            <tr>
              <td>Ease of Interaction</td>
              <td>{feedback.ease_of_interaction}</td>
            </tr>
            <tr>
              <td>Overall Experience</td>
              <td>{feedback.overall_experience}</td>
            </tr>
            <tr>
              <td>Comments</td>
              <td>{feedback.comments || "No comments provided"}</td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <p>No feedback available for this video.</p>
      )}
    </div>
  );
};

export default ViewFeedback;
