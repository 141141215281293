import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Chart from "chart.js/auto";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faVideo,
  faComments,
  faMessage,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "animate.css/animate.min.css";
import "./AdminDashboard.css"; // Custom CSS

const AdminDashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [ratedVideos, setRatedVideos] = useState(0);
  const [unratedVideos, setUnratedVideos] = useState(0);
  const chartRef = useRef(null);
  const growthChartRef = useRef(null); // Ref for the second chart
  const navigate = useNavigate(); // For navigation

   const apiUrl = "https://volumetricvideoevaluation.com/api";

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const userRes = await axios.get(
          `${apiUrl}/admin/user-count`
        );
        const ratedRes = await axios.get(
          `${apiUrl}/admin/rated-videos`
        );
        const unratedRes = await axios.get(
          `${apiUrl}/admin/unrated-videos`
        );

        setUserCount(userRes.data.count);
        setRatedVideos(ratedRes.data.count);
        setUnratedVideos(unratedRes.data.count);

        // Fetch feedback stats and render the chart
        const feedbackRes = await axios.get(
          `${apiUrl}/admin/feedback`
        );
        const feedbackData = feedbackRes.data;

        if (chartRef.current) {
          chartRef.current.destroy();
        }

        const ctx = document.getElementById("feedbackChart").getContext("2d");

        // Create the gradient for the background
        const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Adjust the gradient direction as needed
        gradient.addColorStop(0, "rgba(255, 62, 165, 0.8)"); // Start color (top)
        gradient.addColorStop(1, "rgba(198, 91, 207, 0.8)"); // End color (bottom)

        chartRef.current = new Chart(ctx, {
          type: "bar",
          data: {
            labels: feedbackData.labels,
            datasets: [
              {
                label: "Feedback Score",
                data: feedbackData.scores,
                backgroundColor: gradient, // Use the gradient for the bars
                borderColor: "rgba(255, 255, 255, 1)", // White border for contrast
                borderWidth: 2, // Increase the border width for better visibility
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                ticks: {
                  color: "#ffffff", // White text for Y-axis labels
                },
                grid: {
                  color: "rgba(255, 255, 255, 0.2)", // Optional: light white gridlines
                },
                beginAtZero: true,
              },
              x: {
                ticks: {
                  color: "#ffffff", // White text for X-axis labels
                },
                grid: {
                  color: "rgba(255, 255, 255, 0.2)", // Optional: light white gridlines
                },
              },
            },
            plugins: {
              legend: {
                labels: {
                  color: "#ffffff", // White text for the legend
                },
              },
              title: {
                display: true,
                text: "Feedback Overview",
                color: "#ffffff", // White text for the chart title
                font: {
                  size: 18, // Optional: Increase title font size
                },
              },
            },
          },
        });

        // Fetch User Growth Data and Render Chart
        const growthRes = await axios.get(
         `${apiUrl}/admin/user-growth`
        );
        const growthData = growthRes.data;

        if (growthChartRef.current) {
          growthChartRef.current.destroy();
        }

        const growthCtx = document
          .getElementById("growthChart")
          .getContext("2d");

        // Create the gradient for the line
        const gradientLine = growthCtx.createLinearGradient(0, 0, 0, 400);
        gradientLine.addColorStop(0, "rgba(255, 62, 165, 0.8)");
        gradientLine.addColorStop(1, "rgba(198, 91, 207, 0.8)");

        growthChartRef.current = new Chart(growthCtx, {
          type: "line",
          data: {
            labels: growthData.labels, // Dates
            datasets: [
              {
                label: "User Growth Over Time",
                data: growthData.values, // User counts per day
                fill: false,
                backgroundColor: gradientLine, // Use the gradient for the line
                borderColor: "rgba(198, 91, 207, 0.9)",
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                ticks: {
                  color: "#ffffff", // White text for Y-axis labels
                },
                grid: {
                  color: "rgba(255, 255, 255, 0.2)", // Optional: light white gridlines
                },
                beginAtZero: true,
              },
              x: {
                ticks: {
                  color: "#ffffff", // White text for X-axis labels
                },
                grid: {
                  color: "rgba(255, 255, 255, 0.2)", // Optional: light white gridlines
                },
              },
            },
            plugins: {
              legend: {
                labels: {
                  color: "#ffffff", // White text for the legend
                },
              },
              title: {
                display: true,
                text: "User Growth",
                color: "#ffffff", // White text for the chart title
                font: {
                  size: 18, // Optional: Increase title font size
                },
              },
            },
          },
        });
      } catch (error) {
        console.error("Error fetching admin stats", error);
      }
    };

    fetchStats();
  }, []);

  // Navigate to different pages on click
  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="admin-background">
      {/* Background spots */}
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="d-flex">
        <Container className="mt-5 ml-250">
          <h3 className="text-center text-white mb-5 mt-2">Admin Dashboard</h3>

          {/* Row for navigation icons */}
          <Row className="mb-4 text-center">
            <Col md={2}>
              <Card
                className="shadow-sm transparent-card mb-2"
                onClick={() => handleNavigate("/admin/users")}
                role="button">
                <Card.Body className="icon-card">
                  <FontAwesomeIcon
                    icon={faUser}
                    size="3x"
                    className="text-white"
                  />
                  <Card.Title className="mt-3 text-white">Users</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card
                className="shadow-sm transparent-card mb-2"
                onClick={() => handleNavigate("/admin/videos")}
                role="button">
                <Card.Body className="icon-card">
                  <FontAwesomeIcon
                    icon={faVideo}
                    size="3x"
                    className="heading"
                  />
                  <Card.Title className="mt-3 text-white">Videos</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col md={2}>
              <Card
                className="shadow-sm transparent-card mb-2"
                onClick={() => handleNavigate("/admin/view-feedback")}
                role="button">
                <Card.Body className="icon-card">
                  <FontAwesomeIcon
                    icon={faComments}
                    size="3x"
                    className="heading"
                  />
                  <Card.Title className="mt-3 text-white">Feedback</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card
                className="shadow-sm transparent-card mb-2"
                onClick={() => handleNavigate("/admin/messages")}
                role="button">
                <Card.Body className="icon-card">
                  <FontAwesomeIcon
                    icon={faMessage}
                    size="3x"
                    className="heading"
                  />
                  <Card.Title className="mt-3 text-white">
                    Contact Messages
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col md={2}>
              <Card
                className="shadow-sm transparent-card mb-2"
                onClick={() => handleNavigate("/admin/questionaire-list")}
                role="button">
                <Card.Body className="icon-card">
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    size="3x"
                    className="text-white"
                  />
                  <Card.Title className="mt-3 text-white">
                    Questionaire
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Existing row for statistics */}
          <Row className="mb-4">
            <Col md={4}>
              <Card
                className="text-center shadow-sm gradient-card mb-2"
                text="white">
                <Card.Body>
                  <Card.Title className="heading">User Count</Card.Title>
                  <Card.Text className="heading">{userCount}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                className="text-center shadow-sm gradient-card mb-2"
                text="white">
                <Card.Body>
                  <Card.Title className="heading">Rated Videos</Card.Title>
                  <Card.Text className="heading">{ratedVideos}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                className="text-center shadow-sm gradient-card mb-2"
                text="dark">
                <Card.Body>
                  <Card.Title className="heading">Unrated Videos</Card.Title>
                  <Card.Text className="heading">{unratedVideos}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Row for two charts */}
          <Row className="mb-4">
            <Col md={6}>
              <Card className="shadow-sm transparent-card mb-2">
                <Card.Body>
                  <h4 className="heading">Feedback Overview</h4>
                  <div className="chart-container">
                    <canvas id="feedbackChart"></canvas>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="shadow-sm transparent-card mb-2">
                <Card.Body>
                  <h4 className="heading">User Growth</h4>
                  <div className="chart-container">
                    <canvas id="growthChart"></canvas>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AdminDashboard;
