import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Button } from "react-bootstrap";
import Chart from "chart.js/auto";
import { useRef } from "react";
import "../admin_components/Admin.css";

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const chartRef = useRef(null); // Chart reference

  const apiUrl = "https://volumetricvideoevaluation.com/api";

  // Fetch all users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/admin/users`
        );
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  // Fetch user events and render chart
  useEffect(() => {
    const fetchUserEvents = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/admin/user-events`
        );

        // Delay chart rendering to ensure the canvas is present
        setTimeout(() => {
          renderChart(response.data); // Render the chart after a delay
        }, 500); // 500ms delay to ensure canvas exists
      } catch (error) {
        console.error("Error fetching user events:", error);
      }
    };

    const renderChart = (events) => {
      const canvas = document.getElementById("userEventsChart");
      if (!canvas) {
        console.error("Canvas element not found");
        return;
      }

      const ctx = canvas.getContext("2d");

      // Organize event data for the chart
      const loginData = events
        .filter((event) => event.event_type === "login")
        .map(() => 1);
      const logoutData = events
        .filter((event) => event.event_type === "logout")
        .map(() => 1);
      const labels = events.map((event) =>
        new Date(event.event_time).toLocaleString()
      );

      // Destroy the previous chart if it exists
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      // Create chart
      chartRef.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: labels, // Time of events
          datasets: [
            {
              label: "Login Events",
              data: loginData,
              borderColor: "rgba(75, 192, 192, 1)",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              fill: true,
              spanGaps: true, // Handle missing/null data points
            },
            {
              label: "Logout Events",
              data: logoutData,
              borderColor: "rgba(255, 99, 132, 1)",
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              fill: true,
              spanGaps: true, // Handle missing/null data points
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1,
              },
            },
          },
        },
      });
    };

    fetchUserEvents(); // Fetch user events on component mount
  }, []);

  // Handle user deletion
  const handleDeleteUser = async (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        const response = await axios.delete(
          `${apiUrl}/admin/users/${userId}`
        );
        if (response.status === 200) {
          setUsers(users.filter((user) => user.id !== userId)); // Remove user from state
          alert("User deleted successfully!");
        } else {
          alert("Failed to delete user. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting user:", error);
        alert(
          "Error deleting user: " + error.response?.data?.message ||
            error.message
        );
      }
    }
  };

  if (loading) {
    return <p className="text-white text-center">Loading users...</p>;
  }

  return (
    <div className="admin-background">
      {/* Animated spots */}
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>

      <div className="container mt-5">
        <h2 className="heading">All Users</h2>
        <Table
          striped
          bordered
          hover
          responsive
          className="mt-3 transparent-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.email}</td>
                <td>
                  <Button
                    className="btn-submit-danger"
                    onClick={() => handleDeleteUser(user.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Chart for User Events */}
        <h3 className="mt-5 mb-5 heading">User Events</h3>
        <div className="chart-container">
          <canvas id="userEventsChart"></canvas>
        </div>
      </div>
    </div>
  );
};

export default AdminUsers;
