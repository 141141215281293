import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const ViewQuestionnaire = () => {
  const { id } = useParams();
  const [questionnaire, setQuestionnaire] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to capitalize the first letter of a name
  const capitalizeFirstLetter = (name) => {
    return name ? name.charAt(0).toUpperCase() + name.slice(1) : "Unknown";
  };

const apiUrl = "https://volumetricvideoevaluation.com/api";

  // Fetch questionnaire data when the component mounts
  useEffect(() => {
    const fetchQuestionnaire = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/admin/questionnaire/${id}`
        );

        // Log the response for debugging
        console.log("Fetched Questionnaire:", response.data);

        // Set the questionnaire data into the state
        setQuestionnaire(response.data);
        setLoading(false);
      } catch (error) {
        // Log the error and set the error message
        console.error("Error fetching questionnaire data:", error);
        setError("Failed to load questionnaire details");
        setLoading(false);
      }
    };
    fetchQuestionnaire();
  }, [id]);

  // Handle loading state
  if (loading) {
    return (
      <p className="text-white text-center">Loading questionnaire details...</p>
    );
  }

  // Handle error state
  if (error) {
    return <p className="text-white text-center">{error}</p>;
  }

  // Handle case when no questionnaire data is found
  if (!questionnaire) {
    return <p className="text-white text-center">No questionnaire found.</p>;
  }

  // Display the questionnaire data in a table
  return (
    <div className="admin-background">
      {/* Background spots */}
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="spot"></div>
      <div className="container mt-5">
        <h2 className="text-center text-white mt-4 mb-5">
          Questionnaire for {capitalizeFirstLetter(questionnaire.user_name)}
        </h2>
        <table className="table table-bordered mt-3 transparent-table">
          <tbody>
            <tr>
              <th>Age</th>
              <td>{questionnaire.age || "N/A"}</td>
            </tr>
            <tr>
              <th>Gender</th>
              <td>{questionnaire.gender || "N/A"}</td>
            </tr>
            <tr>
              <th>Education</th>
              <td>{questionnaire.education || "N/A"}</td>
            </tr>
            <tr>
              <th>Field of Study</th>
              <td>{questionnaire.field_of_study || "N/A"}</td>
            </tr>
            <tr>
              <th>Familiarity</th>
              <td>{questionnaire.familiarity || "N/A"}</td>
            </tr>
            <tr>
              <th>Experience</th>
              <td>{questionnaire.experience || "N/A"}</td>
            </tr>
            <tr>
              <th>Understanding</th>
              <td>{questionnaire.understanding || "N/A"}</td>
            </tr>
            <tr>
              <th>Technical Knowledge</th>
              <td>{questionnaire.technical_knowledge || "N/A"}</td>
            </tr>
            <tr>
              <th>Quality Metrics Familiarity</th>
              <td>{questionnaire.quality_metrics || "N/A"}</td>
            </tr>
            <tr>
              <th>Previous Participation</th>
              <td>{questionnaire.previous_participation || "N/A"}</td>
            </tr>
            <tr>
              <th>Quiz 1</th>
              <td>{questionnaire.quiz1 || "N/A"}</td>
            </tr>
            <tr>
              <th>Quiz 2</th>
              <td>{questionnaire.quiz2 || "N/A"}</td>
            </tr>
            <tr>
              <th>Quiz 3</th>
              <td>{questionnaire.quiz3 || "N/A"}</td>
            </tr>
            <tr>
              <th>Consent</th>
              <td>{questionnaire.consent === 1 ? "Yes" : "No"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewQuestionnaire;
