import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import "./LandingPage.css";
import "../App.css";
import Loading from "./Loading";

const LandingPage = () => {
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="landing-page">
      {/* Video Background */}
      <div className="video-container">
        <video autoPlay loop muted playsInline className="background-video">
          <source src="/videos/video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Overlay Content */}
      <div className="overlay-content">
        <div className="container-fluid  text-white py-5 text-center landing-header">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6">
              <h1 className="display-4 fw-bold animate__animated animate__fadeInDown">
                Welcome to Volumetric Video Evaluation!
              </h1>
              <p className="lead mb-4 animate__animated animate__fadeInUp">
                Explore, Learn, and Contribute to Cutting-Edge Research. Join
                our community to participate in exciting studies and evaluate
                the latest media technologies.
              </p>
              <Link
                to="/signup"
                className="btn button_border btn-lg me-2 animate__animated animate__bounceIn">
                <span>Get Started</span>
              </Link>
              <Link
                to="/login"
                className="btn btn-lg animate__animated animate__bounceIn button_border">
                <span>Login</span>
              </Link>
            </div>
          </div>
        </div>

        {/* About Us Section */}
        <div className="container py-5 about-section">
          <div className="row align-items-center">
            <div className="col-md-6 animate__animated animate__fadeInRight">
              <img
                src="/images/about_me_2.png"
                alt="About VidiQuest"
                className="img-fluid "
              />
            </div>
            <div className="col-md-6 animate__animated animate__fadeInLeft">
              <h2 className="fw-bold mb-4 heading">About Us</h2>
              <p className="paragraph mb-4">
                VidiQuest is an innovative platform designed to connect users
                with groundbreaking research in media technology. We provide a
                space for users to evaluate immersive media experiences and
                contribute to the evolution of media technologies. Our platform
                encourages collaboration between users and researchers to push
                the boundaries of media evaluation.
              </p>
              <p className="paragraph mb-4">
                By participating in our studies, you can help shape the future
                of media, virtual reality, AI-driven analytics, and interactive
                experiences. Whether you're a media enthusiast or a researcher,
                VidiQuest is your go-to platform for exploring cutting-edge
                media projects.
              </p>
              <Link
                to="/login"
                className="btn button_border btn-lg animate__animated animate__fadeInUp">
                <span>Rate Videos</span>
              </Link>
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <div className="container py-5 mt-4 mb-4 faq-section">
          <h2 className="text-center mb-5 animate__animated animate__fadeInUp heading">
            Frequently Asked Questions (FAQ)
          </h2>
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="accordion" id="faqAccordion">
                {/* FAQ Item 1 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button text-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne">
                      What is the purpose of this project?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAccordion">
                    <div className="accordion-body">
                      The main goal of this project is to assess the quality of
                      volumetric video content based on the ITU-R BT.500
                      standard. Participants will rate different aspects of
                      video quality, such as visual clarity, depth perception,
                      ease of interaction, and overall enjoyment.
                    </div>
                  </div>
                </div>

                {/* FAQ Item 2 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed text-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo">
                      How does the rating system work?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#faqAccordion">
                    <div className="accordion-body">
                      After viewing each volumetric video clip, participants
                      will complete a feedback form. This form includes both
                      quantitative (Likert scale) and qualitative (open-ended
                      comments) questions on aspects like video clarity, color
                      accuracy, depth perception, and overall experience.
                    </div>
                  </div>
                </div>

                {/* FAQ Item 3 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed text-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree">
                      What is ITU-R BT.500, and why is it important?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faqAccordion">
                    <div className="accordion-body">
                      ITU-R BT.500 is a set of recommendations for assessing
                      video quality subjectively. By following these guidelines,
                      our project ensures that the feedback we collect is
                      reliable, valid, and can be compared across different
                      experiments and studies.
                    </div>
                  </div>
                </div>

                {/* FAQ Item 4 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed text-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour">
                      Who are the participants, and what is required of them?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingFour"
                    data-bs-parent="#faqAccordion">
                    <div className="accordion-body">
                      Participants are recruited from the School of Engineering
                      and the School of Arts and Creative Industries. They will
                      watch a series of volumetric video clips and provide
                      feedback on various quality aspects through our web
                      application.
                    </div>
                  </div>
                </div>

                {/* FAQ Item 5 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed text-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive">
                      What kind of data is being collected?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#faqAccordion">
                    <div className="accordion-body">
                      We collect two types of data: video quality feedback
                      (visual clarity, depth perception, ease of interaction,
                      etc.) and demographic data (age, educational background,
                      familiarity with volumetric content).
                    </div>
                  </div>
                </div>

                {/* FAQ Item 6 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      className="accordion-button collapsed text-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix">
                      How is participant feedback used in this study?
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#faqAccordion">
                    <div className="accordion-body">
                      Participant feedback helps us understand how different
                      users perceive volumetric videos and informs future
                      developments in volumetric video production and playback
                      technologies.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <footer className="background_blue text-white text-center py-3 mt-5 shadow-sm">
          <div className="container">
            <p className="mb-0">© 2024 Faria Hossain. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;
