import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Signup from "./components/Signup";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import LandingPage from "./components/LandingPage";
import Questionnaire from "./components/Questionnaire";
import VideoPlayer from "./components/VideoPlayer";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import AdminLandingPage from "./admin_components/AdminLandingPage";
import AdminLogin from "./admin_components/AdminLogin";
import AdminDashboard from "./admin_components/AdminDashboard";
import AdminUsers from "./admin_components/AdminUsers";
import AppWrapper from "./AppWrapper"; // Import the new AppWrapper
import AdminVideos from "./admin_components/AdminVideos";
import AdminFeedback from "./admin_components/AdminFeedback";
import AdminViewFeedback from "./admin_components/AdminViewFeedback";
import ContactForm from "./components/ContactForm";
import AdminMessages from "./admin_components/AdminContactMessages";
import AdminQuestionaireList from "./admin_components/AdminQuestionnaireList";
import AdminViewQuestionaire from "./admin_components/AdminViewQuestionnaire";
import DistortionExamples from "./components/DistortionExamples";

function App() {
  return (
    <Router>
      <AppWrapper>
        <Routes>
          {/* User Routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/questionnaire" element={<Questionnaire />} />
          <Route path="/rate-videos" element={<VideoPlayer />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/distortion-examples" element={<DistortionExamples />} />

          {/* Admin Routes */}
          <Route path="/admin" element={<AdminLandingPage />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/users" element={<AdminUsers />} />
          <Route path="/admin/videos" element={<AdminVideos />} />
          <Route
            path="/admin/view-feedback/:video_id"
            element={<AdminFeedback />}
          />
          <Route path="/admin/view-feedback/" element={<AdminViewFeedback />} />
          <Route path="/admin/messages/" element={<AdminMessages />} />
          <Route
            path="/admin/questionaire-list/"
            element={<AdminQuestionaireList />}
          />
          <Route
            path="/admin/questionnaire/:id"
            element={<AdminViewQuestionaire />}
          />
        </Routes>
      </AppWrapper>
    </Router>
  );
}

export default App;
